import { createActionGroup, props } from '@ngrx/store';

export const StaticActions = createActionGroup({
  source: 'static',
  events: {
    'Load Tags': props<{ agencyId: string }>(),    
    'Load Tags Success': props<{ tags: any }>(),
    'Load Tags Failure': props<{ error: any }>(),
  }
});
