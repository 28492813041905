import { createFeature, createReducer, on } from '@ngrx/store';
import { StaticActions } from './static.actions';

export const staticFeatureKey = 'static';

export interface StaticState {
  tags: string[] | null
  loading: boolean;
  error: any;
}

export const initialState: StaticState = {
  tags: null,
  loading: true,
  error: null,
};


export const reducer = createReducer(
  initialState,
  on(
    StaticActions.loadTags,
    (state): StaticState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    StaticActions.loadTagsSuccess,
    (state, { tags }): StaticState => ({
      ...state,
      tags: tags,
      loading: false,
      error: null,
    }),
  ),
  on(
    StaticActions.loadTagsFailure,
    (state, { error }): StaticState => ({
      ...state,
      loading: false,
      error,
    }),
  ),

);

export const staticFeature = createFeature({
  name: staticFeatureKey,
  reducer,
});

